import './style.css';

import { useIntersectionObserver } from '../../../../utils/hooks/useIntersectionObserver';
import Item from './Item';

const List = ({
  chunk,
  onClick,
  isTemplateStore,
  uploadedTemplateIds,
  types,
}) => {
  const {
    list,
    loader,
  } = useIntersectionObserver(chunk);

  const handleOnClick = (id, mode) => {
    if (onClick) {
      onClick(id, mode);
    }
  };

  return (
    <>
      {
        Array.isArray(list) && list.length
          ? (
            <div className={'medic-form-list-row mb-5'}>
              {list
                .map((form, i) => (
                  <Item
                    key={`form_list_card_${form.__typename}_${form.id}`}
                    onClick={handleOnClick}
                    form={form}
                    isTemplateStore={isTemplateStore}
                    tabIndex={i}
                    uploadedTemplateIds={uploadedTemplateIds}
                    types={types}
                  />
                ))
              }
            </div>
          ) : null
      }
      <div ref={loader}/>
    </>
  );
};

export default List;
