import './style.css';

import React, { useMemo } from 'react';

import logo from '../../assets/img/logo/logo@3x.png';
import logoNoText from '../../assets/img/logo/logo@3x-no-text.png';
import i18n from '../../services/i18n/i18n';

export default function LoginLayout({ children }) {
  const computedLogo = useMemo(() => (i18n.language === 'fr-FR' ? logo : logoNoText), [i18n.language]);

  return (
    <>
      <div className={'LoginLayout-header'}>
        <div className={'LoginLayout-Logo-Container'}>
          <img src={computedLogo} alt="logo" />
        </div>
        <svg
          width="100%"
          viewBox="0 0 1450 843"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g id="Design-system" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="07-Icons" transform="translate(-104.000000, -2253.000000)" fill="#FFFFFF">
              <g id="wave" transform="translate(104.000000, 2253.000000)">
                <path
                  d="M1368.35087,1.0658141e-14 C1336.17565,37.1367149 1295.62531,80.6608754 1246.69985,130.572481 C1012.81468,369.171861 556.190761,223.788081 301.658528,130.572481 C155.844546,77.1720235 65.8149168,70.0440403 0,82.2391045 L0,0 L1368.35087,0 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className={'LoginLayout-container'}>
        <div className={'LoginLayout-wrapper'}>{children}</div>
      </div>
    </>
  );
}
