import './style.css';

import _ from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AbilityContext } from '../../../services/casl/Can';
import { HOME_MODULES, MODEL_NAMES, PERMISSIONS } from '../../../services/casl/roles';
import { filterAndOrderList } from '../../../utils/format';
import { extractParams } from '../../../utils/router-helper';
import { isNullOrWhitespace } from '../../../utils/utils';
import AuthLayout from '../../layouts/authLayout';
import List from './list';
import SideMenu from './menu';
import { FILTER_MAPPER } from './menu/FilterCard';
import { getMedicMetrics } from './utils/getMedicMetrics';
import { getStatValues } from './utils/getStatValues';

export const sortByFamilyName = (a, b) => {
  const nameA = (a.use_name && a.use_name !== '' ? a.use_name : a.lastname)
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  const nameB = (b.use_name && b.use_name !== '' ? b.use_name : b.lastname)
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  return nameA.localeCompare(nameB);
};

const INITIAL_MEDIC_METRICS = {
  surgery: {
    id: FILTER_MAPPER.SURGERY,
    value: FILTER_MAPPER.SURGERY,
    number: 0,
    patientIdList: null,
  },
  consent: {
    id: FILTER_MAPPER.CONSENT,
    value: FILTER_MAPPER.CONSENT,
    number: 0,
    patientIdList: null,
  },
  patient: {
    id: FILTER_MAPPER.PATIENT,
    value: FILTER_MAPPER.PATIENT,
    number: 0,
    patientIdList: null,
  },
};

const Medic = ({
  deleteTemporarySurvey,
  patients,
  initialSurveyList,
  temporarySurvey,
  activeFilter,
  setActiveFilter,
  medic,
  selectedTeamId,
  isClinicalMode,
  surveyTypes,
  clearSelectedPatient,
  clearData,
}) => {
  const ability = useContext(AbilityContext);
  const navigate = useNavigate();
  const sideMenuRef = useRef({});
  const [isLoading, setIsLoading] = useState(true);
  const [searched, setSearched] = useState();
  const [urlParams, setUrlParams] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setUrlParams(extractParams(searchParams));
  }, [searchParams]);

  const action = useMemo(() => urlParams?.action, [urlParams?.action]);

  useEffect(() => {
    // remove selectedPatient from Redux store
    clearSelectedPatient();
  }, []);

  useEffect(() => {
    resetSearchBar();
    setSearched();
  }, [selectedTeamId]);

  useEffect(() => {
    // open consent modale if action = consent in url
    if (action && action === FILTER_MAPPER.CONSENT) {
      if (sideMenuRef && sideMenuRef.current) {
        if (sideMenuRef.current.isOpen() === false)
          sideMenuRef.current.openModalForm();
      }
    }
  }, [action, sideMenuRef && sideMenuRef.current]);

  const typeIdsWithCountersign = useMemo(() => {
    return Array.isArray(surveyTypes) ? surveyTypes.filter(st => st.should_countersign).map(st => st.id) : [];
  }, [surveyTypes]);

  const medicMetrics = useMemo(() => {
    // metrics are the number consents, number inter, number patient
    return getMedicMetrics(patients, initialSurveyList, isClinicalMode);
  }, [
    JSON.stringify(patients),
    JSON.stringify(initialSurveyList),
    isClinicalMode,
  ]);

  const patientIdList = useMemo(() => {
    // metrics are the number consents, number inter, number patient
    return _.get(medicMetrics, [activeFilter, 'patientIdList'], []);
  }, [
    JSON.stringify(medicMetrics),
    activeFilter,
  ]);

  const displayedList = useMemo(() => {
    const filteredList = (patientIdList || []).map(id => patients.find(p => p.id === id));
    const searchList = filterAndOrderList(filteredList, searched);
    if (Array.isArray(patientIdList)) {
      setIsLoading(false);
    }
    return searchList;
  }, [activeFilter, JSON.stringify(patientIdList), JSON.stringify(patients), JSON.stringify(searched)]);

  const filteredPatientsSurveys = useMemo(() => {
    return Array.isArray(patientIdList) && Array.isArray(initialSurveyList)
      ? initialSurveyList.filter(s => patientIdList.includes(s.patient_id))
      : [];
  }, [patientIdList, JSON.stringify(initialSurveyList)]);

  const statValues = useMemo(() => {
    // stats valus is percentage on the home page
    return getStatValues(filteredPatientsSurveys, isClinicalMode, typeIdsWithCountersign);
  }, [JSON.stringify(filteredPatientsSurveys), isClinicalMode, typeIdsWithCountersign]);

  const handleSearch = (filters) => {
    const newSearch = Array.isArray(filters) ? filters.map((f) => f.id) : null;
    if (!_.isEqual(newSearch, searched)) {
      // (newSearch && (Array.isArray(newSearch) && newSearch.length)) && setIsLoading(true);
      setSearched(!filters ? null : filters.map((f) => f.id));
    }
  };

  const handleFilter = (filter) => {
    if (!_.isEqual(filter, activeFilter)) {
      setIsLoading(true);
      setActiveFilter(filter);
    }
  };

  const resetSearchBar = () => {
    if (sideMenuRef?.current?.resetSearchBar) {
      sideMenuRef.current.resetSearchBar();
    }
  };

  const navigateToMedicalTeamStore = () => {
    navigate(`/teams/${selectedTeamId}/forms`);
  };

  const navigateToPatient = (id) => {
    navigate(`/teams/${selectedTeamId}/patients/${id}`);
  };

  return (
    <AuthLayout>
      <div className={'medic-screen'}>
        {ability.can(PERMISSIONS.READ, {
          __typename: MODEL_NAMES.HOME_MODULES,
          value: HOME_MODULES.SIDE_MENU,
        }) && (
          <SideMenu
            ref={sideMenuRef}
            onFilter={handleFilter}
            deleteTemporarySurvey={deleteTemporarySurvey}
            onForm={navigateToMedicalTeamStore}
            onSearch={handleSearch}
            patientsList={displayedList || []}
            temporarySurvey={temporarySurvey}
            selectedFilter={activeFilter}
            medicMetrics={medicMetrics || INITIAL_MEDIC_METRICS}
            statValues={statValues}
            isSelectedTeam={!isNullOrWhitespace(`${selectedTeamId}`)}
            isClinicalMode={isClinicalMode}
            clearData={clearData}
          />
        )}
        {ability.can(PERMISSIONS.READ, {
          __typename: MODEL_NAMES.HOME_MODULES,
          value: HOME_MODULES.PATIENT_LIST,
        }) && (
          <List
            patientsList={displayedList || []}
            selectedFilter={activeFilter}
            isListLoading={isLoading}
            onClick={navigateToPatient}
            selectedTeam={selectedTeamId}
            medicId={medic && medic.id}
            isClinicalMode={isClinicalMode}
          />
        )}
      </div>
    </AuthLayout>
  );
};

export default Medic;

