import { useCallback, useEffect, useRef, useState } from 'react';

import { scrollToTop } from '../utils';

const hashCode = function (str) {
  let hash = 0, i, chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export function useIntersectionObserver(chunk) {
  const loader = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);

  const handleObserver = useCallback((entries) => {
    if (hasMore) {
      const target = entries[0];
      if (target.isIntersecting) {
        setPage((prev) => prev + 1);
      }
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  useEffect(() => {
    if (Array.isArray(chunk)) {
      loadFunc(1);
      scrollToTop();
      setHasMore(true);
    } else {
      setList([]);
    }

    return function cleanUp() {
      setList([]);
    };
  }, [hashCode(JSON.stringify(chunk)), JSON.stringify(chunk)]);


  useEffect(() => {
    if (page) {
      loadFunc(page);
    }
  }, [page]);

  const loadFunc = (newPage) => {
    if (newPage !== page) {
      setPage(newPage);
    }
    const index = newPage - 1;
    const hasMore = index < chunk.length;
    setList(chunk.slice(0, newPage).reduce((acc, val) => [...acc, ...val], []));
    setHasMore(hasMore);
  };

  return {
    loader,
    list,
  };
}