import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useIntersectionObserver } from '../../../../utils/hooks/useIntersectionObserver';
import Item from './Item';
import ItemCardTemplate from './ItemTemplate';

const InfiniteLoadList = ({
  chunk,
  onClick,
  selectedTeam,
  isClinicalMode,
  medicId,
  selectedFilter,
}) => {
  const { t } = useTranslation();
  const {
    list,
    loader,
  } = useIntersectionObserver(chunk);

  return (
    <>
      {Array.isArray(list) && list.length
        ? (
          list.map((patient, index) => (
            <Item
              key={`patient-list-item-${patient.id}`}
              patient={patient}
              onClick={onClick}
              selectedTeam={selectedTeam}
              isClinicalMode={isClinicalMode}
              medicId={medicId}
              index={index}
            />
          ))
        ) : (
          <ItemCardTemplate
            fullName={t(`patient_card_empty_list${selectedFilter ? `_${selectedFilter}` : ''}`)}
          />
        )}
      <div ref={loader}/>
    </>
  );
};

InfiniteLoadList.propTypes = {
  chunk: PropTypes.array,
  onClick: PropTypes.func,
  selectedTeam: PropTypes.number,
  isClinicalMode: PropTypes.bool,
  medicId: PropTypes.number,
};

export default InfiniteLoadList;
