import _ from 'lodash';

import { surveyStatusMapper } from '../../../../utils';
import { sortByFamilyName } from '../Medic';
import { FILTER_MAPPER } from '../menu/FilterCard';

export const getMedicMetrics = (filteredPatientsList, filteredPatientsSurveys, isClinicalMode) => {
  // intervention == surgery == les cst devraient se trier par date chronologiquement. Les interventions les plus proches apparaissent en haut, les plus lointaines en bas
  // consentement == consent == les cst devraient se trier par état de cst. Rouge en premier, puis jaune, puis bleu
  // consent == consentement
  if (!filteredPatientsList.length) {
    return null;
  }
  const patientListWithSurveys = filteredPatientsList.filter(p => Array.isArray(p.surveys) && p.surveys.length);

  let consentPatients = [...patientListWithSurveys]
    .map((p) => ({
      ...p,
      surveys: p.surveys.sort((a, b) => surveyStatusMapper(_.get(b, 'status'), isClinicalMode) - surveyStatusMapper(_.get(a, 'status'), isClinicalMode)),
    }))
    .sort((a, b) => new Date(_.get(a, 'surveys.0.intervention.date')) - new Date(_.get(b, 'surveys.0.intervention.date')))
    .sort((a, b) => surveyStatusMapper(_.get(b, 'surveys.0.status'), isClinicalMode) - surveyStatusMapper(_.get(a, 'surveys.0.status'), isClinicalMode))
    .filter(s =>
      s.surveys.filter(item => Date.parse(item.intervention.date) >= Date.now()).length > 0,
    )
    .map(p => p.id);
  // const consentPatients = _.uniq(filteredPatientsSurveys.map(s => s.patient_id));

  const surgeryNumbers = _.uniq(filteredPatientsSurveys.reduce((acc, s) => (s ? [...acc, s.intervention] : acc), []).map(s => s && s.number));
  const surgeryPatients = surgeryNumbers !== 0
    ? [...patientListWithSurveys]
      .sort((a, b) => new Date(_.get(a, 'surveys.0.intervention.date')) - new Date(_.get(b, 'surveys.0.intervention.date')))
      .filter(s =>
        s.surveys.filter(item => Date.parse(item.intervention.date) >= Date.now()).length > 0,
      ).map(s => s.id)
    : [];
  // patient == patient
  const sortedPatientList = [...filteredPatientsList].sort(sortByFamilyName);
  const newPatient = sortedPatientList.filter((p) => p.isNew);
  const notNewPatient = sortedPatientList.filter((p) => !p.isNew);

  return {
    surgery: {
      id: FILTER_MAPPER.SURGERY,
      value: FILTER_MAPPER.SURGERY,
      number: surgeryNumbers.length || 0,
      patientIdList: surgeryPatients,
    },
    consent: {
      id: FILTER_MAPPER.CONSENT,
      value: FILTER_MAPPER.CONSENT,
      number: filteredPatientsSurveys.length,
      patientIdList: consentPatients,
    },
    patient: {
      id: FILTER_MAPPER.PATIENT,
      value: FILTER_MAPPER.PATIENT,
      number: sortedPatientList.length,
      patientIdList: newPatient.length ? [...newPatient, ...notNewPatient].map(p => p.id) : sortedPatientList.map(p => p.id),
    },
  };
};