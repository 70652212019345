import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SURVEY_STATUS } from '../../../../utils/enum';
import { capitalizeFirstLetter, isNullOrWhitespace } from '../../../../utils/utils';
import { Button } from '../../../components/styledComponent';
import Step from '../../createFormV2/Step';
import StepRenderModal from './StepRenderModal';

const MODAL_TYPES = {
  [SURVEY_STATUS.FURTHER_INFO]: SURVEY_STATUS.FURTHER_INFO,
  [SURVEY_STATUS.REFUSED]: SURVEY_STATUS.REFUSED,
  Refuse_confirm: 'Refuse_confirm',
  RefuseWithPurpose: 'RefuseWithPurpose',
};

function StepRender({
  step,
  stepNumber,
  pdf,
  steps,
  currentStepNumber,
  numberSteps,
  showSummary,
  answers,
  questionTypes,
  onNext,
  onCancel,
  onPreviousStep,
  handleOnValidation,
  handleOnRejectSurvey,
  isConsent,
  index,
  isLast,
  readOnly,
  onRejectStep,
  isClinicalMode,
  editReasonOnRefusal,
  isRefSignature,
}) {
  const { t } = useTranslation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const { control, setValue, setError } = useFormContext(); // retrieve all hook methods
  const errMessageInput = useWatch({
    control,
    name: 'error_message',
  });

  const openModalAs = (modaleType) => {
    let message;
    let title;
    let showInput = false;
    switch (modaleType) {
      case MODAL_TYPES[SURVEY_STATUS.FURTHER_INFO]:
        title = t('consentFormStep.action.further information.btn');
        message = t('consentFormStep.action.further information.confirmation_message');
        break;
      case MODAL_TYPES[SURVEY_STATUS.REFUSED]:
        title = t('consentFormStep.action.Refuse.btn');
        message = t('consentFormStep.action.Refuse.confirmation_message');
        break;
      case MODAL_TYPES.RefuseWithPurpose:
        setValue('error_message', ''); // clear error message
        title = t('consentFormStep.action.RefuseWithPurpose.btn');
        message = t('consentFormStep.action.RefuseWithPurpose.confirmation_message');
        break;
      default:
          // this gets called
    }
    setModalContent({
      type: modaleType,
      title: title,
      message: message,
      showInput: showInput,
    });
    setIsConfirmModalOpen(true);
  };

  const handleAbort = (modaleType) => {
    switch (modaleType) {
      case MODAL_TYPES[SURVEY_STATUS.FURTHER_INFO]:
        onRejectStep && onRejectStep(t('ConsentRender.rejection_message.further information'), SURVEY_STATUS.FURTHER_INFO);
        break;
      case MODAL_TYPES[SURVEY_STATUS.REFUSED]:
        setModalContent({
          ...modalContent,
          type: MODAL_TYPES.Refuse_confirm,
          message: modalContent.showInput ? t('consentFormStep.action.RefuseWithPurpose.message') : t('consentFormStep.action.ConfirmRefuse.message'),
        });
        break;
      case MODAL_TYPES.RefuseWithPurpose:
        setModalContent({
          ...modalContent,
          type: MODAL_TYPES.Refuse_confirm,
          message: t('consentFormStep.action.RefuseWithPurpose.message'),
          showInput: true,
        });
        break;
      case MODAL_TYPES.Refuse_confirm:
        if (modalContent.showInput && !isNullOrWhitespace(errMessageInput)){
          setError('error_message', {
            type: 'manual',
            message: 'ConsentRender.answer.required',
          });
        } else {
          onRejectStep && onRejectStep(!isNullOrWhitespace(errMessageInput) ? errMessageInput : t('ConsentRender.rejection_message.refused'), SURVEY_STATUS.REFUSED);
        }
        break;
      default:
        // this gets called
    }
  };

  const handleCloseModal = () => {
    setIsConfirmModalOpen(false);
    setModalContent({
      ...modalContent,
      showInput: false,
    });
  };

  return (
    <>
      <div className="consent-screen-loading-bar">
        <div
          className="consent-screen-loading-bar-colored"
          style={{ width: `${numberSteps * (stepNumber + 1)}%` }}
        />
      </div>
      <Step
        prefixName={`steps.${index}`}
        isConsent={isConsent}
        step={step}
        index={index}
        droppable={false}
        onNext={() => onNext && onNext(steps, currentStepNumber, pdf, answers)}
        onCancel={onCancel}
        onPrevious={() => onPreviousStep && onPreviousStep(currentStepNumber, showSummary)}
        onValidation={handleOnValidation}
        questionTypes={questionTypes}
        onRejectSurvey={handleOnRejectSurvey}
        readOnly={readOnly}
        disabled={!isRefSignature}
      />
      <div className="step-render-footer mx-4 d-flex my-2 flex-column flex-md-row align-self-stretch align-items-stretch justify-content-end">
        {step.number > 1 && (
          <Button
            variant="ghost"
            onClick={onPreviousStep}
            type="button"
          >
            {capitalizeFirstLetter(t('consentFormStep.action.previous.btn'))}
          </Button>
        )}
        <Button
          variant="primary"
          onClick={isLast ? null : onNext}
          type={isLast ? 'submit' : 'button'}
        >
          {capitalizeFirstLetter(t('consentFormStep.action.Continue.btn'))}
        </Button>
        { isClinicalMode && !(isConsent && readOnly) && stepNumber+1 === step.number
          ? (
            <>
              {
                isRefSignature && (
                  <Button
                    variant="warning"
                    onClick={() => openModalAs(MODAL_TYPES[SURVEY_STATUS.FURTHER_INFO])}
                    type="button"
                  >
                    {capitalizeFirstLetter(t('consentFormStep.action.further information.btn'))}
                  </Button>
                )
              }
              <Button
                variant="danger"
                onClick={() => openModalAs((isClinicalMode && editReasonOnRefusal) ? MODAL_TYPES.RefuseWithPurpose : MODAL_TYPES[SURVEY_STATUS.REFUSED])}
                type="button"
              >
                {capitalizeFirstLetter(t('consentFormStep.action.RefuseWithPurpose.btn'))}
              </Button>
            </>
          ) : null
        }
      </div>
      <StepRenderModal
        isOpen={isConfirmModalOpen}
        message={modalContent.message}
        showInput={modalContent.showInput}
        onClose={handleCloseModal}
        onAbort={handleAbort}
        type={modalContent.type}
      />
    </>
  );
}

StepRender.propTypes = {
  step: PropTypes.object,
  stepNumber: PropTypes.number,
  // pdf,
  // steps,
  currentStepNumber: PropTypes.number,
  numberSteps: PropTypes.number,
  showSummary: PropTypes.bool,
  // answers,
  // questionTypes,
  onNext :PropTypes.func,
  onCancel :PropTypes.func,
  onPreviousStep :PropTypes.func,
  handleOnValidation :PropTypes.func,
  handleOnRejectSurvey :PropTypes.func,
};

export default StepRender;
