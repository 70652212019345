import { regexSecu } from './regex';


// var postalCodeFormatter = new StringMask('00 000');

export const formatSecuNumber = (number) => {
  if (number.length === 15) number = number.replace(regexSecu, '$1 $2 $3 $4 $5 $6 $7');
  return number;
};

export const formatPostalCode = (number) => number;

export const removeAccents = (str) => {
  if (typeof str === 'string' || str instanceof String) {
    // return removeDiacritics(obj);
    return str.normalize('NFD').replace(/[\u0300-\u036F]/g, '');
  }
  return str;
};

export const filterAndOrderList = (objList, orderList, key = 'id') => {
  if (!objList) return [];
  if (Array.isArray(orderList) && orderList.length) {
    // map id to displayed rank
    return orderList.map(id => objList.find(o => o[key] === id)).filter(el => !!el);
  } else {
    return Array.isArray(orderList) ? [...objList] : objList;
  }
};