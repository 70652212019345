import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import iconEmpty from '../../../../assets/img/illu-pas-dinterventions.png';
import { useIntersectionObserver } from '../../../../utils/hooks/useIntersectionObserver';
import Item from './Item/';


const InfiniteLoadList = ({
  chunk,
  onClick,
  logginUserRole,
  bearerToken,
  smallScreen,
  medic,
  patient,
  tutors,
  isPatient,
  isSigned,
  onResetIsSigned,
  preselectedID,
  hasCounterSign,
  resetAutoLoginSurveyID,
  isClinicalMode,
}) => {
  const { t } = useTranslation();
  const {
    list,
    loader,
  } = useIntersectionObserver(chunk);

  return (
    <>
      {Array.isArray(list) && list.length
        ? (
          list.map((surveyList, i) => (
            <div
              key={`info-patient-survey-number-${i}`}
              className={`item-card-patient${(i) % 2 === 0 ? '-grey' : ''}`}
            >
              <Item
                logginUserRole={logginUserRole}
                bearerToken={bearerToken}
                smallScreen={smallScreen}
                surveyList={surveyList}
                patient={patient}
                intervention={surveyList[0].intervention || {}}
                onClick={onClick}
                tutors={tutors}
                isPatient={isPatient}
                isSigned={isSigned}
                onResetIsSigned={onResetIsSigned}
                preselectedID={preselectedID}
                hasCounterSign={hasCounterSign}
                resetAutoLoginSurveyID={resetAutoLoginSurveyID}
                isClinicalMode={isClinicalMode}
              />
            </div>
          ))
        ) : (
          <div
            className={'d-flex flex-column align-content-center align-items-center patient-contact-historique-empty '}>
            <img src={iconEmpty} alt="empty list"/>
            <div className={'patient-contact-historique-empty-text'}>
              {t('empty_tab_patient')}
            </div>
          </div>
        )}
      <div ref={loader}/>
    </>
  );
};

InfiniteLoadList.propTypes = {
  chunk: PropTypes.array,
  onClick: PropTypes.func,
  selectedTeam: PropTypes.number,
  isClinicalMode: PropTypes.bool,
  medicId: PropTypes.number,
};

export default InfiniteLoadList;
