import { SURVEY_STATUS } from '../../../../utils';
import { SURVEY_STATUS_COLOUR } from '../../../../utils/enum';

export const getStatValues = (filteredPatientsSurveys, isClinicalMode, typeIdsWithCountersign) => {
  if (Array.isArray(filteredPatientsSurveys)) {
    const surveysLength = filteredPatientsSurveys.length;
    const surveysLengthRatio = surveysLength > 0 ? 100 / surveysLength : 0;
    // #################
    // ##     STATS
    // #################
    let coutersigned = 0, surveysSigned = 0, inProgress = 0, furtherInfo = 0, refused = 0, withdrawal = 0;
    if (surveysLength > 0 && surveysLengthRatio > 0) {
      inProgress = filteredPatientsSurveys.filter(s => [SURVEY_STATUS.SENT, SURVEY_STATUS.IN_PROGRESS].includes(s.status)).length;
      refused = filteredPatientsSurveys.filter(s => s.status === SURVEY_STATUS.REFUSED).length;
      withdrawal = filteredPatientsSurveys.filter(s => s.status === SURVEY_STATUS.WITHDRAWAL).length;
      const acceptedSurveyList = filteredPatientsSurveys.filter(s => [SURVEY_STATUS.ACCEPTED, SURVEY_STATUS.COUNTERSIGNED].includes(s.status));
      surveysSigned = acceptedSurveyList.length;
      if (isClinicalMode) {
        // coutersigned surveys with type (with counter signature) and with status accepted
        coutersigned = filteredPatientsSurveys.filter(s => typeIdsWithCountersign.includes(s.type_id) && [SURVEY_STATUS.ACCEPTED].includes(s.status)).length;
        furtherInfo = filteredPatientsSurveys.filter(s => s.status === SURVEY_STATUS.FURTHER_INFO).length;
        surveysSigned = surveysSigned - coutersigned;
      }
    }
    const newStat = [
      {
        order: 1,
        number: coutersigned > 0 ? coutersigned * surveysLengthRatio : 0,
        value: SURVEY_STATUS.COUNTERSIGNED,
        status: SURVEY_STATUS_COLOUR(SURVEY_STATUS.COUNTERSIGNED),
        isVisible: isClinicalMode,
      },
      {
        order: 2,
        number: surveysSigned > 0 ? surveysSigned * surveysLengthRatio : 0,
        value: SURVEY_STATUS.ACCEPTED,
        status: SURVEY_STATUS_COLOUR(SURVEY_STATUS.ACCEPTED),
        isVisible: true,
      },
      {
        order: 3,
        number: inProgress > 0 ? inProgress * surveysLengthRatio : 0,
        value: SURVEY_STATUS.IN_PROGRESS,
        status: SURVEY_STATUS_COLOUR(SURVEY_STATUS.IN_PROGRESS),
        isVisible: true,
      },
      {
        order: 4,
        number: furtherInfo > 0 ? furtherInfo * surveysLengthRatio : 0,
        value: SURVEY_STATUS.FURTHER_INFO,
        status: SURVEY_STATUS_COLOUR(SURVEY_STATUS.FURTHER_INFO),
        isVisible: isClinicalMode,
      },
      {
        order: 5,
        number: refused > 0 ? refused * surveysLengthRatio : 0,
        value: SURVEY_STATUS.REFUSED,
        status: SURVEY_STATUS_COLOUR(SURVEY_STATUS.REFUSED, isClinicalMode),
        isVisible: true,
      },
      {
        order: 6,
        number: withdrawal > 0 ? withdrawal * surveysLengthRatio : 0,
        value: SURVEY_STATUS.WITHDRAWAL,
        status: SURVEY_STATUS_COLOUR(SURVEY_STATUS.WITHDRAWAL),
        isVisible: isClinicalMode,
      },
    ];
    return newStat.filter(s => ('isVisible' in s) && s.isVisible === true);
  }
};
