import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SURVEY_STATUS } from '../../../../utils';
import Card from '../../../components/card';
import Filter from '../../../components/filter';

const StatCard = ({
  statValues = [],
  isClinicalMode,
}) => {
  const { t } = useTranslation();
  return (
    <Card>
      {statValues.map((stat, index) => {
        const {
          number,
          value,
          status,
        } = stat;
        return (
          <Filter
            key={`stat_card_${value}_${index}`}
            primaryText={`${Math.round(number)}%`}
            secondaryText={t(`medic.stats.${isClinicalMode ? 'clinicalStudy.' : 'hospitalisation.'}${value}`)}
            value={value}
            type={'lines'}
            status={status}
            isStat={true}
          />
        );
      })}
    </Card>
  );
};

export default StatCard;
StatCard.propTypes = {
  statValues: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number,
      value: PropTypes.oneOf([SURVEY_STATUS.IN_PROGRESS, SURVEY_STATUS.REFUSED, SURVEY_STATUS.ACCEPTED, SURVEY_STATUS.COUNTERSIGNED, SURVEY_STATUS.FURTHER_INFO, SURVEY_STATUS.WITHDRAWAL]),
      status: PropTypes.oneOf(['blue', 'red', 'yellow', 'green', 'black', 'grey', 'bluegrey']),
    }),
  ),
};