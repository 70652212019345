import './style.css';

import React, { useMemo } from 'react';

import logo from '../../../../assets/img/logo/logo-easy-consent-dark-copie@3x.png';
import logoNoText from '../../../../assets/img/logo/logo-no-text-dark-3x.png';
import i18n from '../../../../services/i18n/i18n';

const AuthLayoutLogo = ({ onClick }) => {
  const computedLogo = useMemo(() => (i18n.language === 'fr-FR' ? logo : logoNoText), [i18n.language]);
  return (
    <div className={'authlayout-logo-container'}>
      <img src={computedLogo} alt="authlayout-logo" onClick={onClick} />
    </div>
  );
};

export default AuthLayoutLogo;
